main {
  box-sizing: border-box;
  text-align: center;
  margin-left: 0rem;
  margin-top: 4rem;
}

@media screen and (min-width: 800px) {
  main {
    margin-left: 0rem;
    margin-top: 2rem;
  }
}

@media screen and (min-width: 1300px) {
  main {
    margin-left: 14rem;
  }
}

@media screen and (min-width: 1700px) {
  main {
    margin-left: 19rem;
  }
}

.PrivateSwipeArea-root {
  pointer-events: none !important;
}

.PhoneClient .selected-flag {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 60px;
  padding: 0;
}

.PhoneClient .selected-flag:hover {
  border-radius: 8px 0px 0px 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 60px;
  padding: 0;
}

.PhoneClient .selected-flag.open {
  border-radius: 8px 0px 0px 8px !important;
}

/* Intro JS */
.introjs-tooltip {
  background-color: transparent !important;
  box-shadow: none !important;
}

.introjs-overlay {
  z-index: -2 !important;
}

.introjs-helperLayer {
  box-shadow: #2ED588 0px 0px 20px 4px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
  border-radius: 15px !important;
}

.introjs-arrow {
  display: none !important;
}

.introjs-tooltip.introjs-right {
  background-color: transparent !important;
}

.introjs-tooltip-header {
  display: none !important;
}

.introjs-tooltipbuttons {
  margin-top: 3rem !important;
  border-top: none !important;
  opacity: 1 !important;
  position: absolute;
  display: none;
  right: -4%;
}

@media screen and (min-width: 360px) {
  .introjs-tooltipbuttons {
    margin-top: 4rem !important;
  }
}

@media screen and (min-width: 400px) {
  .introjs-tooltipbuttons {
    margin-top: 5rem !important;
    right: -20%;
  }
}

@media screen and (min-width: 800px) {
  .introjs-tooltipbuttons {
    margin-top: 5rem !important;
    display: inline;
    right: -70%;
  }
}

@media screen and (min-width: 1000px) {
  .introjs-tooltipbuttons {
    margin-top: 5.5rem !important;
    right: -80%;
  }
}

@media screen and (min-width: 800px) {
  .introjs-tooltipbuttons {
    margin-top: 12.5rem !important;
    /* transform: translateX(265%); */
  }
}

.introjs-bullets {
  display: none !important;
}

.introjs-tooltiptext {
  background-color: transparent !important;
}

.introjs-prevbutton {
  display: none !important;
}

.introjs-button:focus {
  box-shadow: none !important;
}

.introjs-nextbutton {
  font-family: 'Poppins', sans-serif !important;
  border-radius: 15px !important;
  text-shadow: none !important;
  font-size: .7rem !important;
  font-weight: 500 !important;
  border: none !important;
  float: none !important;
  padding: .4rem .9rem;
  color: #2ED588;
}

@media screen and (min-width: 400px) {
  .introjs-nextbutton {
    font-size: .9rem !important;
    padding: .5rem 1rem;
  }
}

.introjs-nextbutton:hover {
  color: #2ED588;
}

#ImagesContainer {
  transform: translate(-50%, -50%);
  flex-direction: column;
  position: fixed;
  display: none;
  width: 90%;
  left: 50%;
  top: 50%;
}

@media screen and (min-width: 800px) {
  #ImagesContainer {
    display: flex;
    width: auto;
  }
}

#ImagesContainer h1 {
  font-family: 'Poppins', sans-serif;
  background-color: #2ED588;
  border-radius: 25px;
  padding: .5rem 1rem;
  text-align: center;
  margin: 1rem auto;
  font-weight: 500;
  font-size: .9rem;
  color: white;
}

@media screen and (min-width: 400px) {
  #ImagesContainer h1 {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 800px) {
  #ImagesContainer h1 {
    display: none;
  }
}

#ImagesContainer img {
  width: 100%;
}

@media screen and (min-width: 800px) {
  #ImagesContainer img {
    width: 700px;
  }
}

#ImagesContainerVideo {
  transform: translate(-50%, -50%);
  flex-direction: column;
  position: fixed;
  display: flex;
  width: 90%;
  left: 50%;
  top: 50%;
}

@media screen and (min-width: 800px) {
  #ImagesContainerVideo {
    width: auto;
  }
}

#ImagesContainerVideo h1 {
  font-family: 'Poppins', sans-serif;
  padding: .5rem 1rem;
  text-align: center;
  font-weight: 500;
  font-size: .9rem;
  margin: 0 auto;
}

@media screen and (min-width: 400px) {
  #ImagesContainerVideo h1 {
    font-size: 1.2rem;
  }
}

#ImagesContainerVideo img {
  width: 100%;
}

@media screen and (min-width: 800px) {
  #ImagesContainerVideo img {
    width: 700px;
  }
}

.texts-container {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  display: flex;
  padding: 1rem;
  height: 90%;
  width: 95%;
}

.texts-container h1, .texts-container p {
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.texts-container h1 {
  margin: 1rem auto 0 auto;
  font-size: .6rem;
  font-weight: 600;
  width: 70%;
}

@media screen and (min-width: 400px) {
  .texts-container h1 {
    font-size: 1rem;
  }
}

@media screen and (min-width: 800px) {
  .texts-container h1 {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1000px) {
  .texts-container h1 {
    font-size: 1.6rem;
  }
}

.texts-container p {
  font-size: .6rem;
}

@media screen and (min-width: 400px) {
  .texts-container P {
    font-size: .9rem;
  }
}

@media screen and (min-width: 800px) {
  .texts-container P {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1000px) {
  .texts-container P {
    font-size: 1.1rem;
  }
}

.video-section {
  margin-bottom: 2rem;
  height: 50%;
  width: 75%;
}

@media screen and (min-width: 1000px) {
  .video-section {
    height: 250px;
  }
}

.iframe-video {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.play-video {
  font-family: 'Poppins', sans-serif;
  background-color: #2ED588;
  text-decoration: none;
  padding: .4rem 5rem;
  border-radius: 18px;
  font-weight: 500;
  cursor: pointer;
  color: white;
}

.skip-video {
  font-family: 'Poppins', sans-serif;
  margin-top: .6rem;
  color: #2ED588;
  font-size: .9rem;
  cursor: pointer;
}

/* Check Terminos y Condiciones */
.MuiCheckbox-root .MuiSvgIcon-root {
  height: 1.6rem !important;
  width: 1.6rem !important;
}

.MuiCheckbox-root.Mui-checked {
  color: #2ED588 !important;
}

/* Payment */
.rccs {
  width: 100%;
}

.rccs__card {
  width: 90%;
}

@media screen and (min-width: 400px) {
  .rccs__card {
    width: 290px;
  }
}

.MuiDrawer-modal {
  left: 100% !important;
  width: 22vw;
}