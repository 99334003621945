.DataSection h1 {
    margin-bottom: .5rem;
    font-size: 1.2rem;
    font-weight: 500;
}

@media screen and (min-width: 500px) {
    .DataSection h1 {
        font-size: 1.4rem;
    }
}

@media screen and (min-width: 1000px) {
    .DataSection h1 {
        font-size: 1.6rem;
    }
}

.DataSection h2 {
    font-size: .75rem;
    font-weight: 400;
    margin-top: 0;
}

@media screen and (min-width: 500px) {
    .DataSection h2 {
        font-size: 1.1rem;
    }
}

@media screen and (min-width: 1000px) {
    .DataSection h2 {
        font-size: 1.4rem;
    }
}

.DataSection p {
    font-weight: 400;
    font-size: .6rem;
}

@media screen and (min-width: 500px) {
    .DataSection p {
        font-size: .7rem;
    }
}

@media screen and (min-width: 1000px) {
    .DataSection p {
        font-size: .9rem;
    }
}

.AvatarContainer {
    margin: -4rem auto auto auto;
    width: 100px;
}

@media screen and (max-height: 500px) {
    .AvatarContainer {
        margin: -3rem auto auto auto;
        width: 80px;
    }
}

.AvatarContainer img {
    width: 100%;
}

.VideoSection {
    border-radius: 10px;
}

.VideoSection iframe {
    border-radius: 10px;
    position: relative; 
    user-select: none;
    height: 180px; 
    margin: auto;
    width: 90%; 
}

@media screen and (min-width: 500px) {
    .VideoSection iframe {
        height: 200px;
    }
}

@media screen and (min-width: 1000px) {
    .VideoSection iframe {
        height: 250px;
    }
}

.InputStyles {
    border: 1px solid rgba(197, 197, 197, 0.8);
    box-sizing: border-box;
    padding-left: .7rem;
    border-radius: 7px;
    font-size: .85rem;
    height: 2.5rem;
    width: 100%;
}

.InputStyles:focus-visible{
    border: 1px solid #E1C478;
    outline: none;
}