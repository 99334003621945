.SelectImgModalContainer h1 {
    font-size: 1.6rem;
    font-weight: 600;
    margin: .3rem 0;
}

.InputContainer {
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    display: flex;
}

.InputContainer img {
    border: 3px solid #2ED588;
    border-radius: 50%;
    height: 150px;
    width: 150px;
}