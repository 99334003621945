.GroupButton {
    border: 1px solid #BFBBBD;
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 2rem;
    align-items: center;
    padding: 0 .5rem;
    display: flex;
    height: 4rem;
    width: 100%;
    gap: 10px;
}

@media screen and (min-width: 500px) {
    .GroupButton {
        width: 90%;
    }
}

@media screen and (min-width: 900px) {
    .GroupButton {
        max-width: 500px;
        width: 100%;
    }
}

.ButtonItem {
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    margin: auto 0;
    padding: .3rem;
    display: flex;
    height: 80%;
    width: 50%;
}

@media screen and (min-width: 900px) {
    .ButtonItem {
        padding: 0;
    }
}

.ButtonItem p {
    font-weight: 500;
    font-size: .8rem;
}

@media screen and (min-width: 900px) {
    .ButtonItem p {
        font-size: 1rem;
    }
}