.PalletsImgsContainer { 
    border: 1px solid #BFBBBD;
    background-color: white;
    box-sizing: border-box; 
    border-radius: 10px;
    overflow-x: scroll;
    max-width: 680px;
    padding: 1rem;
    margin: auto;
    width: 100%;
}

@media screen and (min-width: 1600px) {
    .PalletsImgsContainer {
        margin: 0;
    } 
}

@media screen and (min-width: 1800px) {
    .PalletsImgsContainer {
        overflow-x: hidden;
    }
}

.LinearGradientContainer {
    background: linear-gradient(90deg, white 0%, transparent 20%, transparent 88%, white 100%);
    pointer-events: none;
    margin-left: -1rem;
    position: absolute;
    max-width: 678px;
    width: 99.5%;
    height: 62px;
    z-index: 1;
}

@media screen and (min-width: 700px) {
    .LinearGradientContainer {
        height: 82px;
    }
}

@media screen and (min-width: 600px) {
    .LinearGradientContainer {
        display: none;
    }
}

.SelectImgsContainer {
    justify-content: space-between;
    min-width: 465px;
    display: flex;
    gap: 20px;
}

.SelectImgBackContainer {
    position: relative;
}

.SelectImgBackContainer h2 {
    font-size: 1.2rem;
    font-weight: 500;
}

.ImagesContainer {
    border: 1px solid #BFBBBD;
    justify-content: center;
    background-size: cover;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 60px;
    width: 60px;
}

@media screen and (min-width: 700px) {
    .ImagesContainer {
        height: 80px;
        width: 80px;
    }
}