.LogOutModalContainer h1 {
    font-size: 1.5rem;
}

.LogOutModalContainer p {
    color: #BFBBBD;
}

@media screen and (min-width: 450px) {
    .LogOutModalContainer h1 {
        font-size: 1.9rem;
    }
}

.ButtonsContainer {
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin: auto;
    width: 85%;
    gap: 10px;
}