/* Texts */
.TextContainer {
    padding: 0;
}

.TextContainer h2 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
}

.TextContainer h3 {
    font-size: .85rem;
    font-weight: 500;
    margin-top: 0;
}

.TextContainer p {
    font-size: .75rem;
}

.TextContainer span {
    font-weight: 600;
    font-size: .85rem;
}

.ListText {
    padding-left: 1.5rem;
    font-size: .75rem; 
}

.ListText li {
    margin: .3rem 0;
}