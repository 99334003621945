.FormContainer {
    margin-bottom: 0rem;
    text-align: left;
    padding: 1rem;
}

@media screen and (min-width: 500px) {
    .FormContainer {
        margin-bottom: 0rem;
        padding: 2rem;
    }
}

@media screen and (min-width: 800px) {
    .FormContainer {
        margin-bottom: 0rem;
        padding: 2rem;
    }
}

@media screen and (min-width: 1200px) {
    .FormContainer {
        padding: 3rem 1rem 1rem 3rem;
    }
}

@media screen and (min-width: 1600px) {
    .FormContainer {
        padding: 3rem 1rem 1rem 5rem;
    }
}

.ComponentsGeneralContainer {
    display: block;
    margin: 1rem auto auto auto;
    width: 100%;
}

@media screen and (min-width: 600px) {
    .ComponentsGeneralContainer {
        width: 70%;
    }
}

.ComponentsContainer {
    padding-bottom: 0rem;
    width: 100%;
}