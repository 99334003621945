.Step1GeneralContainer {
    margin-bottom: 2rem;
}

.Step1Container {
    grid-template-rows: 45% 55%;
    display: grid;
}

@media screen and (min-width: 950px) {
    .Step1Container {
        grid-template-columns: 70% 30%;
        display: grid;
    }
}

.Title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

@media screen and (min-width: 800px) {
    .Title {
        font-size: 1.8rem;
    }
}

.InputsContainer {
    grid-row: 2/3;
    padding: 0rem;
}

@media screen and (min-width: 950px) {
    .InputsContainer {
        grid-row: 1/2;
    }
}

.InputPlain {
    border: 1px solid rgba(197, 197, 197, 0.8);
    box-sizing: border-box;
    padding-left: .7rem;
    border-radius: 7px;
    font-size: .85rem;
    height: 2.5rem;
    width: 100%;
}

.InputPlain:focus-visible{
    border: 1px solid #2ED588;
    outline: none;
}

.InputsPhoneContainer {
    flex-direction: row;
    display: flex;
    width: 100%;
    gap: 10px;
}

.SelectCodePhone {
    max-width: 100px;
    min-width: 90px;
    width: 90px;
}

@media screen and (min-width: 950px) {
    .SelectCodePhone {
        width: 100px;
    }
}

.InputPlainPhone {
    border: 1px solid rgba(197, 197, 197, 0.8);
    width: calc(100% - 1rem);
    padding-left: .7rem;
    border-radius: 7px;
    font-size: .85rem;
    grid-column: 2/3;
    height: 2.2rem;
}

@media screen and (min-width: 950px) {
    .InputPlainPhone {
        width: 90%;
    }
}

.InputPlainPhone:focus-visible {
    border: 1px solid #2ED588;
    outline: none;
}

.PhotoContainer {
    align-items: center;
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
}

@media screen and (min-width: 950px) {
    .PhotoContainer {
        grid-column: 2/3;
        grid-row: 1/3;
    }
}

.InputContainer {
    width: 60%;
}

/* Texts */
.TextContainer {
    padding: 2.5rem 0 0 0;
}

.TextContainer h2 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
}

.TextContainer h3 {
    font-size: .85rem;
    font-weight: 500;
    margin-top: 0;
}

.TextContainer p {
    font-size: .75rem;
}

.TextContainer span {
    font-weight: 600;
    font-size: .85rem;
}