.SwitchComponentContainer {
    /* margin: .5rem 0rem; */
    padding: .5rem;
}

.SwitchSection {
    justify-content: flex-start;
    display: flex;
}

.SwitchSection h2 {
    font-size: 1.1rem;
    margin-left: 1rem;
    font-weight: 500;
    color: #393939;
}

@media screen and (min-width: 400px) {
    .SwitchSection h2 {
        font-size: 1.3rem;
    }
}

.SwitchComponentContainer p {
    color: #6A6A6A;
    font-size: 1rem;
    margin: 0rem;
}

@media screen and (min-width: 900px) {
    .SwitchComponentContainer p {
        font-size: .9rem;
    }
}

@media screen and (min-width: 1100px) {
    .SwitchComponentContainer p {
        font-size: 1rem;
    }
}

.Text2 {
    display: none;
}

@media screen and (min-width: 600px) {
    .Text2 {
        display: block;
    }
}