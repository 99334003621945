.ModalContainer h1 {
    font-weight: 500;
    font-size: 1rem;
}

@media screen and (min-width: 450px) {
    .ModalContainer h1 {
        font-size: 1.7rem;
    }
}

.ModalContainer h1 span {
    font-weight: 600;
}

.ModalContainer p {
    color: #7E7E7E;
    font-size: .8rem;
}

@media screen and (min-width: 450px) {
    .ModalContainer p {
        font-size: 1rem;
    }
}
