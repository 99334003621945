body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F8FF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Stepper */
.MuiStepper-root {
  width: 100% !important;
}

@media screen and (min-width: 1200px) {
  .MuiStepper-root {
    margin-left: 0rem !important;
  }
}

.MuiSvgIcon-root.Mui-completed {
  border-radius: 50% !important;
  color: #2ED588 !important;
}

.lineCompleted {
  border-color: #2ED588 !important;
}

.MuiStepLabel-label {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  font-size: 8px !important;
}

@media screen and (min-width: 350px) {
  .MuiStepLabel-label {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 450px) {
  .MuiStepLabel-label {
    font-size: 14px !important;
  }
}

nav ul li {
  position:relative;
}

/* Step 2 Create User */
.AddHourButton {
  box-shadow: 0px 2.91309px 2.91309px rgba(0, 0, 0, 0.25);
  background-color: #2ED588 !important;
  text-transform: none !important;
  padding: .4rem .9rem !important;
  border-radius: 6px !important;
  color: white !important;
  display: flex;
  gap: 5px;
}

.AddHourButton p {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  font-size: .8rem;
  margin: 0;
}

@media screen and (min-width: 400px) {
  .AddHourButton p {
    font-size: 1rem;
  }
}

.SelectHourInput .MuiInput-root{
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  color: #BFBBBD;
}

.SelectHourInput .MuiInput-root .MuiInput-input {
  margin-bottom: -2px !important;
  padding: 0 !important;
}

.ButtonsContainer {
  display: flex;
}

.ButtonsContainerActive {
  justify-content: flex-end;
  display: flex;
}

@media screen and (min-width: 400px) {
  .ButtonsContainerActive {
    justify-content: center;
  }
}

/* Login */
.EmailTextField:hover {
  outline-offset: 1px solid red !important;
}

/* Inputs type=number */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

  opacity: 0;

}

.recharts-surface {
  border-radius: 0px 0px 15px 15px;
}

a.whatsapp{
  bottom: 50px !important;
}

div.whatspro-window{
  bottom: 140px !important;
}

.CreatableSelect {
  font-size: .9rem;
  font-weight: 400;
}

.PhoneInput {
  margin-top: -245px !important;
  max-width: 240px !important;
}

@media screen and (min-width: 1000px) {
  .PhoneInput {
    max-width: 300px !important;
    margin-top: 5px !important;
  }
}

.Step0TasksTutorialVideo {
  border-radius: 10px;
  position: relative; 
  height: 180px; 
  margin: auto;
  width: 90%; 
}

@media screen and (min-width: 500px) {
  .Step0TasksTutorialVideo {
      height: 200px;
  }
}

@media screen and (min-width: 1000px) {
  .Step0TasksTutorialVideo {
      height: 250px;
  }
}

/* Date picker Home */
.Datepicker-input {
  font-family: 'Poppins', sans-serif;
  background-color: transparent;
  border: 1px solid #2ED588;
  box-sizing: border-box;
  padding: .3rem 1rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: .7rem;
  width: 16em
}

.Datepicker-input:focus-visible {
  border: 1px solid #2ED588;
  outline: none;
}

.rmdp-arrow {
  border: solid #2ED588 !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-week-day {
  color: #2ED588 !important;
}

.rmdp-range {
  background-color: #2ED588 !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #2ED588 !important;
}

.rmdp-arrow-container:hover {
  background-color: #E3FFF2 !important;
}