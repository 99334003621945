.RankingItemContainer {
    justify-content: space-between;
    background-color: #fff;
    box-sizing: border-box;
    padding: .5rem .7rem;
    align-items: center;
    border-radius: 15px;
    width: 100%;
}

.RankingItemContainerQuestion {
    justify-content: space-between;
    background-color: #fff;
    box-sizing: border-box;
    padding: .5rem .7rem;
    align-items: center;
    border-radius: 15px;
    width: 100%;
}

@media screen and (min-width: 600px) {
    .RankingItemContainer {
        display: flex;
        height: auto;
        width: 100%;
    }
    .RankingItemContainerQuestion{
        width: 100%;
    }
}

@media screen and (min-width: 600px) {
    .RankingItemContainerQuestion {
        padding: 1rem .7rem;
        display: flex;
        height: auto;
    }
}

.DataSection {
    display: flex;
    gap: 15px;
}

.RankingItemContainer img {
    height: 64px;
    width: 64px;
}

.NameContainer {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    display: flex;
}

.NameContainer h2 {
    word-wrap: break-word;
    text-align: left;
    font-weight: 600;
    font-size: .9rem;
    margin: 0;
}

@media screen and (min-width: 1500px) {
    .NameContainer h2 {
        font-size: 1rem;
    }
}

.NameContainer p {
    color: #BFBBBD;
    font-size: .8rem;
    font-weight: 400;
    text-align: left;
    margin: 0;
}

.MessagesContainer {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: .3rem .5rem;
    border-radius: 15px;
    align-items: center;
    display: flex;
    margin: auto;
    height: 90%;
    margin: 0;
}


@media screen and (min-width: 450px) {
    .MessagesContainer {
        padding: .3rem .7rem;
        height: 100%;
    }
}

.MessagesContainer p {
    color: #473C41;
    font-size: .8rem;
    font-weight: 600;
    width: 100%;
    margin: 0;
}

@media screen and (min-width: 310px) {
    .MessagesContainer {
        width: auto;
    }
}

.BottomSection {
    justify-content: space-around;
    align-items: center;
    display: block;
    width: 100%;
}

@media screen and (min-width: 310px) {
    .BottomSection {
        display: flex;
    }
}

@media screen and (min-width: 450px) {
    .BottomSection {
        display: flex;
        width: auto;
    }
}

.TitleMobile {
    margin: 1rem 0 .5rem 0;
    color: #9DA6BA;
    font-weight: 500;
    font-size: .9rem;
}

@media screen and (min-width: 450px) {
    .TitleMobile {
        display: none;
    }
}