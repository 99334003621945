.DeleteModalContainer h1 {
    font-size: 1.5rem;
    margin: 0;
}

.DeleteModalContainer p {
    color: #7E7E7E;
    font-size: .9rem;
}

.ButtonsContainer {
    justify-content: space-between;
    margin: 1.5rem auto .5rem auto;
    display: flex;
    width: 90%;
    gap: 10px;
}

@media screen and (min-width: 500px) {
    .ButtonsContainer {
        gap: 0;
    }
}