.Chip {
    font-family: 'Poppins', sans-serif;
    border: 1px solid #DCDCDC;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    padding: .4rem 1rem;
    border-radius: 20px;
    width: max-content;
    user-select: none;
    font-weight: 500;
    font-size: .9rem;
    min-width: 125px;
    cursor: pointer;
    display: flex;
}