.CrossContainer {
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 2%;
}

@media screen and (min-width: 800px) {
    .CrossContainer {
        right: 3%;
        top: 3%;
    }
}

.TitleModal {
    font-size: 1.3rem;
    margin: 0;
}

@media screen and (min-width: 800px) {
    .TitleModal {
        font-size: 1.5rem;
    }
}

.SubtitleModal {
    margin: .5rem 0;
    font-size: .8rem;
}

@media screen and (min-width: 800px) {
    .SubtitleModal {
        font-size: 1rem;
    }
}

.ImagesContainer {
    grid-template-columns: repeat(2, 1fr);
    height: calc(100% - 160px);
    padding: 1rem 0rem;
    overflow-y: auto;
    display: grid;
    width: 100%;
    gap: 20px;
}

@media screen and (min-width: 700px) {
    .ImagesContainer {
        grid-template-columns: repeat(4, 1fr);
        overflow-y: auto;
        max-height: 400px;
        gap: 15px;
    }
}

.PhotosContainer {
    justify-content: center;
    border-radius: 50%;
    display: flex;
}

.ProfileLogoContainer {
    position: relative;
    border-radius: 50%;
    display: flex;
    height: 90px;
    width: 90px;
}

@media screen and (min-width: 800px) {
    .ProfileLogoContainer {
        height: 100px;
        width: 100px;
    }
}

.ProfileLogoContainer img {
    border-radius: 50%;
    user-select: none;
    cursor: pointer;
}

.PhotoProfileInput {
    background-color: transparent;
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 0;
}

.ButtonContainer {
    padding: .5rem 0rem 0rem 0rem;
    text-align: right;
}

@media screen and (min-width: 800px) {
    .ButtonContainer {
        padding: .5rem 1rem 0rem 0rem;
    }
}

.TickImages {
    position: absolute !important;
    height: 25px !important;
    width: 25px !important;
    display: none;
    right: 0%;
    top: 5px;
}