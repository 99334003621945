.SelectColorsContainer {
    border: 1px solid #BFBBBD;
    background-color: white;
    box-sizing: border-box;
    padding: .5rem 1.5rem;
    border-radius: 10px;
    position: relative;
    max-width: 680px;
    margin: auto;
    width: 100%;
}

@media screen and (min-width: 1600px) {
    .SelectColorsContainer {
        margin: 0; 
    }
}

.SelectColorsContainer h2 {
    font-weight: 500;
    font-size: 1.2rem;
}

.PalletsColorsContainer {
    overflow-x: auto; 
    padding: 1rem 0;
}

@media screen and (min-width: 800px) {
    .PalletsColorsContainer {
        padding: 0; 
    }
}

.FullColorsContainer {
    justify-content: space-between;
    overflow-x: auto;
    min-width: 465px;
    overflow: auto;
    display: flex;
    gap: 20px;
}

.ColorPreview {
    justify-content: center;
    margin-bottom: .5rem;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 60px;
    width: 60px;
}

@media screen and (min-width: 700px) {
    .ColorPreview {
        height: 80px;
        width: 80px;
    }
}

.ColorPreviewEdit {
    border: 2px solid #BFBBBD;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10px;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 60px;
    width: 60px;
}

@media screen and (min-width: 700px) {
    .ColorPreviewEdit {
        height: 80px;
        width: 80px;
    }
}

.PredColorsContainer {
    justify-content: space-between;
    margin-bottom: 1rem;
    min-width: 465px;
    display: flex;
    gap: 20px;
}

.PalletContainer {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    height: 100px;
    width: 60px;
}

@media screen and (min-width: 700px) {
    .PalletContainer {
        height: 130px;
        width: 80px;
    }
}

.PalletContainerEdit {
    border: 2px solid #BFBBBD;
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    height: 100px;
    width: 60px;
}

@media screen and (min-width: 700px) {
    .PalletContainerEdit {
        height: 130px;
        width: 80px;
    }
}

.BackColorContainer {
    justify-content: center;
    pointer-events: none;
    border-radius: 10px;
    align-items: center;
    position: absolute;
    display: flex;
    width: 100%;
    height: 65%;
    top: 0;
}

.BackColorContainerEdit {
    border-width: 0px 0px 2px 0px;
    background-color: white;
    border-color: #BFBBBD;
    justify-content: center;
    box-sizing: border-box;
    border-style: solid;
    border-radius: 10px;
    align-items: center;
    position: absolute;
    display: flex;
    width: 100%;
    height: 65%;
    top: 0;
}

.LinearGradientContainer {
    background: linear-gradient(90deg, white 0%, transparent 20%, transparent 88%, white 100%);
    margin-left: -1.5rem;
    pointer-events: none;
    position: absolute;
    height: 60px;
    width: 100%;
    z-index: 1;
}

@media screen and (min-width: 700px) {
    .LinearGradientContainer {
        height: 80px;
    }
}

@media screen and (min-width: 635px) {
    .LinearGradientContainer {
        display: none;
    }
}

.LinearGradient2Container {
    background: linear-gradient(90deg, white 0%, transparent 20%, transparent 88%, white 100%);
    margin-left: -1.5rem;
    pointer-events: none;
    position: absolute;
    height: 105px;
    width: 100%;
    z-index: 1;
}

@media screen and (min-width: 700px) {
    .LinearGradient2Container {
        height: 135px;
    }
}

@media screen and (min-width: 635px) {
    .LinearGradient2Container {
        display: none;
    }
}