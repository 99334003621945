.ChatContainer {
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);  */
    /* transform: translate(-50%, -50%); */
    grid-template-rows: 12% 88%;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    position: relative;
    user-select: none;
    min-width: 350px;
    max-width: 350px;
    display: grid;
    width: 350px;
    height: 86vh;
    width: 100%;
    right: 0rem;
    left: 0rem;
    top: 0rem;
}

@media screen and (min-height: 600px) {
    .ChatContainer {
        height: 550px;
    }
}

.TitleChat {
    border-radius: 10px 10px 0px 0px;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    max-width: 350.5px;
    min-width: 350.5px;
    margin-left: -1px;
    grid-row: 1/2;
    width: 100.5%;
    display: flex;
    padding: 1rem;
}

.TitleChat p {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    grid-row: 1/2;
    width: 70%;
}

.AvatarContainer {
    justify-content: flex-end;
    display: flex;
    width: 30%;
}

.MessagesChatContainer {
    position: relative;
    overflow-y: auto;
}

.MessagesChatContainer::-webkit-scrollbar {
    background-color: white;
    -webkit-appearance: none;
    right: 10px;
}

.MessagesChatContainer::-webkit-scrollbar:vertical {
    width: 8px;
}

.MessagesChatContainer::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
    display: none;
} 

.MessagesChatContainer::-webkit-scrollbar-thumb {
    background-color: #F0EFEF;
    border: 7px solid transparent;
    border-radius: 20px;
}

.MessagesChatContainer::-webkit-scrollbar-track {
    border-radius: 10px;  
}

.ComercialText {
    background-color: transparent;
    padding: .3rem .5rem .3rem 0;
    box-sizing: border-box;
    padding-right: .5rem;
    text-align: right;
    user-select: none;
    font-size: .7rem;
    position: sticky;
    cursor: default;
    width: 100%;
    margin: 0;
    right: 0;
    top: 0;
}

.ComercialText span {
    font-weight: 600;
}

.WelcomeText {
    background-color: #EEEEEE;
    border-radius: 8px;
    margin-left: 1rem;
    margin-top: 1rem;
    padding: .6rem;
    width: 11rem;
}

.WelcomeText p {
    word-wrap: break-word;
    font-size: .7rem;
    color: #393939;
    margin: 0;
}

.QuestionsContainer {
    padding: .7rem .7rem 1rem 0;
    flex-direction: column;
    justify-content: right;
    text-align: right;
    display: flex;
}

.Question {
    padding: .4rem .8rem;
    border-radius: 8px;
    width: max-content;
    font-size: .75rem;
    font-weight: 500;
    margin: .5rem 0;
}

.FirstQuestion {
    background-color: #EEEEEE;
    justify-content: center;
    align-items: center;
    border-radius: 8px; 
    height: 30.78px;
    margin: .5rem 0;
    display: flex;
    width: 200px; 
}

.LastQuestion {
    justify-content: center;
    align-items: center;
    border-radius: 8px; 
    width: 154.45px; 
    height: 30.78px;
    margin: .5rem 0;
    display: flex;
}

.OpacityElement {
    background-color: white;
    border-radius: 11px;
    height: .6rem;
    opacity: .5;
    width: 80%;
}

.CrossContainer {
    position: absolute;
    cursor: pointer;
    right: -4%;
    top: -8%;
}

@media screen and (min-width: 800px) {
    .CrossContainer {
        display: none;
    }
}