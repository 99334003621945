.Title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

@media screen and (min-width: 800px) {
    .Title {
        font-size: 1.8rem;
    }
}

.MessagesContainer {
    padding: 1rem 0rem;
}

.MessagesContainer h2 {
    font-weight: 500;
    font-size: 1rem;
}


.InputMessage {
    font-family: 'Poppins', sans-serif;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 1rem;
    padding: 1rem;
    resize: none;
    width: 95%;
}

.InputMessage:focus-visible {
    outline: none !important;
    border: 1px solid #2ED588 !important;
}

.MessageComponent {
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1rem;
    width: 95%;
}

.TitleSection {
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 30px;
}

.TitleSection h2 {
    font-weight: 500;
    font-size: .8rem;
}

@media screen and (min-width: 500px) {
    .TitleSection h2 {
        font-size: 1rem;
    }
}

.TextAreaContainer {
    margin-top: 1rem;
    width: 96%;
}

.InputMessage2 {
    font-family: 'Poppins', sans-serif;
    border: 1px solid #BFBBBD;
    border-radius: 10px;
    font-size: 1rem;
    padding: 1rem;
    resize: none;
    width: 90%;
}

@media screen and (min-width: 500px) {
    .InputMessage2 {
        width: 94%;
    }
}

@media screen and (min-width: 900px) {
    .InputMessage2 {
        width: 96%;
    }
}

@media screen and (min-width: 1100px) {
    .InputMessage2 {
        width: 98%;
    }
}

@media screen and (min-width: 1400px) {
    .InputMessage2 {
        width: 100%;
    }
}

.InputMessage2:focus-visible {
    border: 1px solid #2ED588 !important;
    outline: none !important;
}

/* Texts */
.TextContainer {
    padding: 2.5rem 0 0 0;
}

.TextContainer h2 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
}

.TextContainer h3 {
    font-size: .85rem;
    font-weight: 500;
    margin-top: 0;
}

.TextContainer p {
    font-size: .75rem;
}

.TextContainer span {
    font-weight: 600;
    font-size: .85rem;
}

.ListText {
    list-style-type: decimal; 
    padding-left: 1.5rem;
    font-size: .75rem; 
}