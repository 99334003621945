.ModalColor {
    transform: translate(-50%, -50%);
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
    position: absolute;
    padding: 1.5rem;
    width: 100%;
    left: 50%;
    top: 50%;
}

@media screen and (min-width: 400px) {
    .ModalColor {
        width: 400px;
    }
}

.ModalColor h2 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
}

@media screen and (min-width: 400px) {
    .ModalColor h2{
        font-size: 1.4rem;
    }
}

.ModalColor p {
    font-size: 1rem;
    margin: 0;
}

@media screen and (min-width: 400px) {
    .ModalColor p{
        font-size: 1.2rem;
        margin: 1rem 0;
    }
}

.InputGeneralContainer {
    grid-template-columns: repeat(2, 1fr);
    padding: 1.5rem 0rem;
    display: grid;
    gap: 20px;
}

.ColorContainer {
    border: 1px solid #BFBBBD;
    border-radius: 10px;
    grid-column: 1/2;
    height: 60px;
    width: 100%;
}

.InputContainer {
    border: 1px solid #BFBBBD;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    padding-left: .5rem;
    grid-column: 2/3;
    display: flex;
    height: 60px;
    width: 100%;
}

.InputContainer p {
    pointer-events: none;
    position: absolute;
    font-size: .9rem;
}

.SelectPredColors {
    display: block;
}

.SelectPredColors h2{
    font-size: 1.2rem;
    margin-top: 1rem;
}

@media screen and (min-width: 400px) {
    .SelectPredColors h2{
        font-size: 1.4rem;
        margin-top: 0rem;
    }
}

.ButtonContainer {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    width: 100%;
}

.ColorInput {
    height: 100%;
    width: 100%;
    opacity: 0;
}

.SpanColor {
    font-family: 'Poppins';
    font-size: 1rem;
}

.InputTextColor {
    font-family: 'Poppins';
    text-align: center;
    max-width: 90px;
    font-size: 1rem;
    border: none;
    width: 90px;
}

.InputTextColor:focus-visible {
    outline: none;
}