.Title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

@media screen and (min-width: 800px) {
    .Title {
        font-size: 1.8rem;
    }
}

.TextContainer h2 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 0;
}

.TextContainer h3 {
    font-size: .85rem;
    font-weight: 500;
    margin-top: 0;
}

.TextContainer p {
    font-size: .75rem;
}