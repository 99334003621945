.CreateUserModalArrowTop {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    z-index: 999;
    width: 80vw;
    top: 3.5rem;
    left: -1rem;
}

@media screen and (min-width: 400px) {
    .CreateUserModalArrowTop {
        width: 300px;
    }
}

@media screen and (min-width: 800px) {
    .CreateUserModalArrowTop {
        left: -4rem;
    }
}

@media screen and (min-width: 910px) {
    .CreateUserModalArrowTop {
        left: -9rem;
    }
}

@media screen and (min-width: 1150px) {
    .CreateUserModalArrowTop {
        left: -1rem;
    }
}

@media screen and (min-width: 1500px) {
    .CreateUserModalArrowTop {
        width: 400px;
        left: -3rem;
    }
}

@media screen and (min-width: 1700px) {
    .CreateUserModalArrowTop {
        left: -1rem;
    }
}

.CreateUserModalArrowTop:after {
    box-shadow: 0 1px 14px rgba(0,0,0,.2);
    border-radius: 15px;
    position: absolute;
    height: 100%;
    content: "";
    width: 100%;
    left: 0;
    top: 0;
}

.CreateUserModalArrowTop:before {
    box-shadow: 0 1px 14px rgba(0,0,0,.2);
    transform: rotate(45deg);
    background: #fff;
    position: absolute;
    height: 20px;
    z-index: 998;
    content: "";
    width: 20px;
}

.CreateUserModalArrowTop:before {
    left: calc(50% - 55px);
    top: -8px;
}

@media screen and (min-width: 800px) {
    .CreateUserModalArrowTop:before {
        left: calc(50% - 10px);
    }
}

@media screen and (min-width: 910px) {
    .CreateUserModalArrowTop:before {
        left: calc(50% + 40px);
    }
}

/* .CreateUserModal.arrow-right:before {
    top: calc(50% - 10px);
    right: -8px;
}

.CreateUserModal.arrow-bottom:before {
    left: calc(50% - 10px);
    bottom: -8px;
}

.CreateUserModal.arrow-left:before {
    top: calc(50% - 10px);
    left: -8px;
} */

.CreateUserModalWrapper {
    border-radius: 15px;
    background: #fff;
    position: relative;
    padding: 15px;
    z-index: 998;  
}

@media screen and (min-width: 910px) {
    .CreateUserModalWrapper {
        padding: 20px;
    }
}

.CreateUserComponents {
    grid-template-columns: 20% 80%;
    display: grid;
}

.LogoSection {
    justify-content: center;
    align-items: center;
    display: flex;
}

.TextSection {
    padding-left: 1rem;
    text-align: left;
}

.TextSection h2 {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
}

@media screen and (min-width: 910px) {
    .TextSection h2 {
        font-size: 1.2rem;
    }
}

.TextSection p {
    margin: .5rem 0 0 0;
    font-size: .8rem;
}

@media screen and (min-width: 910px) {
    .TextSection p {
        font-size: .9rem;
    }
}

.BottomSection {
    justify-content: space-between;
    margin: .5rem auto auto auto;
    align-items: center;
    display: flex;
    width: 95%;
}

@media screen and (min-width: 910px) {
    .BottomSection {
        margin: 1rem auto auto auto;
    }
}

.BottomSection p {
    color: #B8B8B8;
    font-size: .8rem;
    margin: 0;
}