.LoaderFlowyContainer {
    backdrop-filter: blur(5px);
    justify-content: center;
    align-items: center;
    position: fixed;
    text-align: center;
    display: flex;
    height: 110%;
    width: 100%;
    z-index: 2;
    top: -10%;
    left: 0;
}

.LoaderFlowyContainer img {
    height: 10rem;
}