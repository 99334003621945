.Title {
    font-size: 1.5rem;
    font-weight: 600;
}

@media screen and (min-width: 500px) {
    .Title {
        font-size: 2rem;
    }
}

.DataSection {
    padding: 0;
}

.DataTitle {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
}

@media screen and (min-width: 1500px) {
    .DataTitle {
        font-size: 1.4rem;
    }
}

.DataSectionContainer {
    justify-content: space-between;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

@media screen and (min-width: 500px) {
    .DataSectionContainer {
        width: 98%;
    }
}

@media screen and (min-width: 700px) {
    .DataSectionContainer {
        display: flex;
        width: 96%;
        gap: 10px;
    }
}

@media screen and (min-width: 800px) {
    .DataSectionContainer {
        width: 96%;
    }
}

@media screen and (min-width: 1050px) {
    .DataSectionContainer {
        grid-template-columns: 65% 35%;
        display: grid;
        width: 96%;
        gap: 15px;
    }
}

@media screen and (min-width: 1400px) {
    .DataSectionContainer {
        width: 90%;
    }
}

.DataSectionItems {
    padding: 1rem 1.5rem 2.5rem 1rem;
    border: 1px solid #C4C4C4;
    background-color: white;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
}

@media screen and (min-width: 1100px) {
    .DataSectionItems {
        padding: 1rem 1rem 2.5rem 1rem;
    }
}

.DataSectionItems1 {
    padding: 1rem 1.5rem 2.5rem 1rem;
    border: 1px solid #C4C4C4;
    background-color: white;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border-radius: 8px;
    position: relative;
}

@media screen and (min-width: 700px) {
    .DataSectionItems1 {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 1100px) {
    .DataSectionItems1 {
        padding: 1rem 1rem 2.5rem 1rem;
    }
}

.Data {
    color: #808080;
    font-weight: 500;
}

.Data span {
    word-wrap: break-word;
    font-weight: 400;
}

@media screen and (min-width: 400px) {
    .Data span {
        word-wrap: normal;
    }
}

.CardData {
    align-items: center;
    text-align: center;
    padding: 1.5rem 0;
    display: block;
}

@media screen and (min-width: 400px) {
    .CardData {
        display: flex;
        gap: 10px;
    }
}

.CardData p {
    word-wrap: break-word;
    font-size: 1.2rem;
    color: #808080;
    font-weight: 500;
    margin: 0;
}

@media screen and (min-width: 400px) {
    .CardData p {
        word-wrap: normal;
    }
}

@media screen and (min-width: 1400px) {
    .CardData p {
        margin: 1rem;
    }
}

.ButtonContainer {
    position: absolute;
    height: 2rem;
    bottom: 1rem;
    right: 1rem;
}

.TableSection {
    border-radius: 10px;
    margin-top: 1rem;
    width: 97.5%;
}


@media screen and (min-width: 1400px) {
    .TableSection {
        width: 91%;
    }
}

.TableContainer {
    border: 1px solid #C4C4C4;
    background-color: white;
    margin-bottom: 1rem;
    border-radius: 10px;
    overflow-x: auto;
    padding: 1rem;
    width: 91%;
}

@media screen and (min-width: 500px) {
    .TableContainer {
        width: 93.5%;
    }
}

@media screen and (min-width: 1000px) {
    .TableContainer {
        width: 99.5%;
        padding: 0;
    }
}

@media screen and (min-width: 1400px) {
    .TableContainer {
        width: 100%;
    }
}

.TableContainer::-webkit-scrollbar {
    height: 10px;
}

.TableContainer::-webkit-scrollbar-track {
    background: white;
    border-radius: 5px;
    margin: 3px;
}

.TableContainer::-webkit-scrollbar-thumb {
    background: #2ED588;
    border-radius: 5px;
}

.TableContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}
