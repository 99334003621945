.TopContainer {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;
    display: flex;
    width: 90%;
}

@media screen and (min-width: 500px) {
    .TopContainer {
        width: 400px;
    }
}

.TopContainer p {
    margin-top: .5rem;
    color: #626262;
}

.TopContainer p span {
    font-weight: 600;
}

.TopContainer img {
    width: 160px;
}

.CardContainer {
    justify-content: space-between; 
    flex-direction: column; 
    display: flex;
    height: 75%; 
}

.AlertContainer {
    border: 1px solid #2ED588;
    margin: 0rem auto 2rem auto;
    background-color: white;
    border-radius: 10px;
    padding: .5rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    
}

@media screen and (min-width: 500px) and (max-width: 900px){
    .AlertContainer {
        width: 400px;
    }
}

@media screen and (min-width: 1000px) {
    .AlertContainer {
        width: 400px;
    }
}

.AlertContainer p {
    font-size: .7rem;
    margin: 0;
}

@media screen and (min-width: 400px) {
    .AlertContainer p {
        font-size: .8rem;
    }
}

.Title {
    margin-bottom: .5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 5px;
}

.Title p {
    font-weight: 600;
    font-size: .7rem;
}

@media screen and (min-width: 400px) {
    .Title p {
        font-size: .8rem;
    }
}

.FormContainer {
    padding: 1rem;
}

@media screen and (min-width: 1200px) {
    .FormContainer {
        padding: 2rem 2rem 1rem 2rem;
    }
}

.FormContainer h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}

.CardData {
    flex-direction: column;
    padding: .7rem 0;
    display: flex;
    gap: 2px;
}

@media screen and (min-width: 900px) {
    .CardData {
        gap: 15px;
    }
}

.TopContainerForm {
    margin: 1.5rem auto 0rem auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 90%;
}

@media screen and (min-width: 500px) {
    .TopContainerForm {
        margin: 1rem auto;
        width: 400px;
    }
}

@media screen and (min-width: 900px) {
    .TopContainerForm {
        display: none;
    }
}

.TopContainerForm p {
    margin: .5rem 0;
    color: #626262;
}

.TopContainerForm p span {
    font-weight: 600;
}

.TopContainerForm img {
    width: 160px;
}

.ButtonSection {
    padding: .5rem 0 0 0;
}

.MPSection {
    justify-content: center;
    align-items: center;
    padding: .5rem 0 0 0;
    display: flex;
    gap: 10px;
}

.MPSection p {
    font-size: .9rem;
    font-weight: 500;
    margin: 0;
}

.MPSection img {
    width: 5rem;
}

.InputsCard {
    font-family: 'Poppins', sans-serif;
    border: 1px solid #BFBBBD;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: .7rem; 
    margin: .2rem 0; 
    padding: .6rem; 
    width: 100%;
}

@media screen and (min-width: 1200px) {
    .InputsCard {
        font-size: 1rem; 
        padding: .8rem; 
        margin: 0;
    }
}

.InputsCard:hover, .InputsCard:focus-visible {
    border: 1px solid black;
    outline: none;
}

.InputsCard:active, .InputsCard:focus {
    border: 1px solid #2ED588;
}

.InputsCard::placeholder {
    color: #BFBBBD;
}

.HelperErrorText {
    padding-left: .5rem;
    font-size: .7rem;
    text-align: left;
    color: red;
    margin: 0;
}

.HelperText {
    padding-left: .5rem;
    font-size: .7rem;
    text-align: left;
    color: #626262;
    margin: 0;
}