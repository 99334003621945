.Title {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0;
}

@media screen and (min-width: 350px) {
    .Title {
        font-size: 2rem;
    }
}

.SubtitleSection {
    display: block;
    width: 100%;
}

@media screen and (min-width: 910px) {
    .SubtitleSection {
        justify-content: space-between;
        align-items: center;
        display: flex;
    }
}

.SubtitleText {
    width: 100%;
}

@media screen and (min-width: 600px) {
    .SubtitleText {
        width: 70%;
    }
}

.SubtitleText p {
    font-size: 1rem;
    display: flex;
}


.ButtonContainer {
    justify-content: flex-start;
    align-items: center;
    position: relative;
    display: flex;
    width: 100%;
}

@media screen and (min-width: 910px) {
    .ButtonContainer {
        justify-content: flex-end;
        width: 32%;
    }
}

@media screen and (min-width: 1100px) {
    .ButtonContainer {
        width: 30%;
    }
}

.UsersContainer {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem 0rem;
    display: flex;
}