.UserContainer {
    grid-template-columns: 10% 45% 25% 20%;
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
    align-items: center;
    padding: 0rem 1rem;
    max-width: 1200px;
    margin: 1rem 0;
    height: 4.5rem;
    display: none;
    width: 98%;
}

@media screen and (min-width: 750px) {
    .UserContainer {
        display: grid;
    }
}

@media screen and (min-width: 1700px) {
    .UserContainer {
        grid-template-columns: 10% 35% 35% 20%;
    }
}

.UserDataContainer {
    display: flex;
    gap: 20px;
}

.AvatarSection {
    align-items: center;
    display: flex;
}

.AvatarSection img {
    border: 1px solid #2ED588;
    border-radius: 50%;
    height: 60px;
    width: 60px;
}

.NameSection {
    text-align: left;
}

.SwitchContainer {
    display: none;
}

@media screen and (min-width: 750px) {
    .SwitchContainer {
        display: flex;
    }
}

.UserName {
    margin: .5rem 0 .3rem 0;
    font-size: .9rem;
    font-weight: 500;
}

.ShortUserName {
    margin: .5rem 0 .3rem 0;
    font-size: .9rem;
    font-weight: 500;
    display: flex;
}

.UserPosition {
    font-size: .8rem;
    color: #BFBBBD;
    font-weight: 400;
    margin: 0;
}

.UserPhone {
    font-size: .8rem;
    color: #BFBBBD;
    font-weight: 400;
    margin: 0;
}

.IconsContainer {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    gap: 10px;
}

.PhoneContainer {
    height: 60px;
}

.UserContainerMobile {
    grid-template-columns: 30% 70%;
    border-radius: 10px;
    align-items: center;
    padding: 0rem 1rem;
    margin: 1rem 0;
    display: grid;
    height: 4rem;
    width: 97%;
}

@media screen and (min-width: 750px) {
    .UserContainerMobile {
        display: none;
    }
}

.OptionsSection {
    justify-content: flex-end;
    grid-column: 2/3;
    display: flex;
}