.PhotoSelectContainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem 1rem;
    margin: 0 auto;
    display: flex;
    gap: 10px;
}

@media screen and (min-width: 900px) {
    .PhotoSelectContainer {
        padding: 1rem;
        margin: auto;
        height: 90%;
        gap: 0;
    }
}

.ImageSection {
    border-radius: 50%;
    height: 170px;
    width: 170px;
    margin: auto;
}

@media screen and (min-width: 1600px) {
    .ImageSection {
        height: 200px;
        width: 200px;
    }
}

.ImageSection img {
    border: 3px solid #2ED588;
    border-radius: 50%;
    height: 170px;
    width: 170px;
}

@media screen and (min-width: 1600px) {
    .ImageSection img {
        height: 200px;
        width: 200px;
    }
}