.SuccessSubContainer h1 {
    margin: .5rem auto;
    font-size: 1.2rem;
    font-weight: 600;
}

@media screen and (min-width: 350px) {
    .SuccessSubContainer h1 {
        font-size: 1.5rem;
    }
}

.SuccessSubContainer p {
    color: #7E7E7E;
    font-size: .7rem;
}

@media screen and (min-width: 350px) {
    .SuccessSubContainer p {
        font-size: .8rem;
    }
}