.PaymentMethodRow {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;
}

.PaymentMethodRow img {
    height: 2.5rem;
}

.PaymentMethodRow p {
    font-size: 1.2rem;
    margin: 0;
}