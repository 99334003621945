.MuiInputBase-root,
.MuiTypography-root {
  font-family: 'Poppins', sans-serif !important;
}
body{
    margin: 0;
}
.chat-frame::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-frame {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
