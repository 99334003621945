.Title {
    font-size: 1.5rem;
    font-weight: 600;
}

@media screen and (min-width: 500px) {
    .Title {
        font-size: 2rem;
    }
}

.TopSection {
    display: block;
}

@media screen and (min-width: 800px) {
    .TopSection {
        width: 80%;
    }
}

@media screen and (min-width: 1550px) {
    .TopSection {
        width: 100%;
    }
}

.FormContainer {
    box-sizing: border-box;
    display: block;
}

@media screen and (min-width: 800px) {
    .FormContainer {
        width: 80%;
    }
}

@media screen and (min-width: 1550px) {
    .FormContainer {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 45% 45% 45%;
        display: grid;
        width: 100%;
        gap: 15px;
    }
}

.TitleSection {
    align-items: center;
    user-select: none;
    display: flex;
    width: 100%;
    gap: 10px;
}

@media screen and (min-width: 500px) {
    .TitleSection {
        width: auto;
    }
}

.TitleSection h2 {
    font-size: .9rem;
    font-weight: 400;
}

@media screen and (min-width: 500px) {
    .TitleSection h2 {
        font-size: 1.2rem;
    }
}

.TextAreaButton {
    font-family: 'Poppins', sans-serif;
    border: 1px solid #BFBBBD;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 1rem;
    padding: 1rem;
    resize: none;
    width: 99%;
}

@media screen and (min-width: 500px) {
    .TextAreaButton {
        width: 90%;
    }
}

@media screen and (min-width: 900px) {
    .TextAreaButton {
        width: 500px;
    }
}

.TextAreaButton:focus-visible {
    border: 1px solid #2ED588;
    outline: 0;
}

.DelayButtonSection {
    grid-column: 1/2;
    grid-row: 3/4;
}

@media screen and (min-width: 1550px) {
    .DelayButtonSection {
        grid-column: 2/3;
        grid-row: 2/3;
    }
}

.AligSection {
    grid-column: 1/2;
}

@media screen and (min-width: 1550px) {
    .AligSection {
        grid-column: 1/2;
        grid-row: 1/2;
    }
}

.DelayButtonSection {
    grid-column: 1/2;
    grid-row: 3/4;
}

@media screen and (min-width: 1550px) {
    .DelayButtonSection {
        grid-column: 2/3;
        grid-row: 1/2;
    }
}

.TextButtonSection {
    grid-column: 1/2;
}

@media screen and (min-width: 1550px) {
    .TextButtonSection {
        grid-column: 1/2;
        grid-row: 2/3;
    }
}

.DelayBulletsPointSection {
    grid-column: 1/2;
}

@media screen and (min-width: 1550px) {
    .DelayBulletsPointSection {
        grid-column: 2/3;
        grid-row: 2/3;
    }
}

.CharactersMaxText {
    text-align: right;
    font-size: .7rem;
    color: #BFBBBD;
    width: 99%;
    margin: 0;
}

@media screen and (min-width: 500px) {
    .CharactersMaxText {
        width: 90%;
    }
}

@media screen and (min-width: 900px) {
    .CharactersMaxText {
        width: 500px;
    }
}