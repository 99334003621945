.UserDataContainer {
    background-color: white;
    grid-column: 1/2;
    display: flex;
    gap: 10px;
}

@media screen and (min-width: 450px) {
    .UserDataContainer {
        grid-column: 0;
        gap: 30px;
    }
}

.AvatarSection {
    align-items: center;
    display: flex;
}

.AvatarSection img {
    border: 1px solid #2ED588;
    height: 50px;
    width: 50px;
}

.NameSection {
    text-align: left;
}

.SwitchContainer {
    display: none;
}

@media screen and (min-width: 750px) {
    .SwitchContainer {
        display: flex;
    }
}

.UserName {
    margin: .5rem 0 .3rem 0;
    font-size: .9rem;
    font-weight: 500;
}

.UserPosition {
    font-size: .8rem;
    color: #BFBBBD;
    font-weight: 400;
    margin: 0;
}

.UserPhone {
    font-size: .8rem;
    color: #BFBBBD;
    font-weight: 400;
    margin: 0;
}

.IconsContainer {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    gap: 10px;
}

.PhoneContainer {
    margin-top: -6px;
}

.UserContainerMobile {
    grid-template-columns: 90% 10%;
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
    align-items: center;
    padding: 0rem 1rem;
    max-width: 500px;
    margin: 1rem 0;
    display: grid;
    height: 4rem;
    width: 100%;
}

@media screen and (min-width: 750px) {
    .UserContainerMobile {
        display: none;
    }
}

.OptionsSection {
    justify-content: flex-end;
    grid-column: 2/3;
    display: flex;
}