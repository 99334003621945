.ClicksContainer {
    box-shadow: 0px 7.11616px 183.241px rgba(50, 50, 71, 0.01), 0px 7.11616px 104.963px rgba(50, 50, 71, 0.06);
    background-color: white;
    border-radius: 15px;
    position: relative;
    grid-column: 2/3;
    height: 250px;
    width: 100%;
}

@media screen and (min-width: 600px) {
    .ClicksContainer {
        width: 100%;
    }
}

@media screen and (min-width: 1500px) {
    .ClicksContainer {
        height: 295px;
    }
}

.GraphicSection {
    position: absolute;
    margin: auto;
    height: 50%;
    width: 100%;
    bottom: 0;
}

@media screen and (min-width: 1500px) {
    .GraphicSection {
        height: 60%;
    }
}

.DataSection {
    padding: 1rem 1rem 1rem 2rem;
    position: absolute;
    top: 0;
}

@media screen and (min-width: 1000px) {
    .DataSection {
        padding: 1rem 1rem 1rem 1.5rem;
    }
}

.DataSection h1 {
    font-weight: 500;
    text-align: left;
    font-size: 18px;
    margin: 0;
}

@media screen and (min-width: 500px) {
    .DevicesGeneralContainer h1 {
        font-size: 22px;
    }
}

.Data {
    align-items: center;
    display: flex;
}

.Data h2 {
    font-weight: 700;
    font-size: 3rem;
    margin: 0;
}

.Data p {
    font-size: .8rem;
    margin: 0rem 0rem -1.4rem 1rem;
}