.NavContainer {
    background-color: #393939;
    text-align: center;
    padding-top: 0rem;
    position: fixed;
    height: 4rem;
    width: 100%;
    z-index: -1;
    left: 0;
    top: 0;
}

@media screen and (min-width: 1300px) {
    .NavContainer {
        padding-top: 3rem;
        width: 13rem;
        height: 100%;
    }
}

@media screen and (min-width: 1300px) {
    .NavContainer {
        width: 14rem;
    }
}

@media screen and (min-width: 1700px) {
    .NavContainer {
        width: 19rem;
    }
}

.NavMobile {
    background-color: #393939 !important;
    box-shadow: none !important;
    display: block;
    height: 4rem;
    width: 100%;
}

@media screen and (min-width: 1300px) {
    .NavMobile {
        display: none !important;
    }
}

.CrossContainer {
    position: absolute;
    cursor: pointer;
    right: 4%;
    top: 2%;
}

@media screen and (min-width: 1300px) {
    .CrossContainer {
        display: none;
    }
}

.NavWeb {
    background-color: #393939;
    display: none;
}

@media screen and (min-width: 1300px) {
    .NavWeb {
        display: block;
    }
    
    .NavMobile {
        display: none;
    }
}

.NavWeb ul li {
    user-select: none;
    cursor: pointer;
}

.ImgTopMobile {
    margin-left: -3rem;
    width: 170px;
}

@media screen and (min-width: 350px) {
    .ImgTopMobile {
        margin-left: -5rem;
    }
}

@media screen and (min-width: 500px) {
    .ImgTopMobile {
        margin-left: -2rem;
        width: 50%;
    }
}

.ImgTopMobile1 {
    margin-left: -2rem;
    margin-top: .4rem;
    width: 150px;
}

.ImgTop {
    width: 12vw;
}

.ImgTopGift { 
    padding-top: 1.6rem;
    width: 60%;
}

@media screen and (min-width: 1200px) {
    .ImgTop {
        margin: 0 2rem 0 0;
        width: 9vw;
    }
}

@media screen and (min-width: 1500px) {
    .ImgTop {
        width: 9vw;
    }
}

.StoreName {
    margin: 2rem auto 1rem auto;
    padding-left: 1.4rem;
    text-align: left;
    font-weight: 600;
    color: white;
}

@media screen and (min-width: 1200px) {
    .StoreName {
        padding-left: 2rem;
        padding-right: 1rem;
    }
}

@media screen and (min-width: 1700px) {
    .StoreName {
        padding: 1rem 1rem 1rem 3rem;
    }
}

.StoreNameMobile {
    margin: 1rem auto;
    text-align: left;
    font-weight: 600;
    color: white;
    width: 50%;
}

@media screen and (min-width: 500px) {
    .StoreNameMobile {
        margin: 2rem auto 1rem auto;
        padding-left: 2rem;
        width: 80%;
    }
}

.NavContainer ul {
    padding: 5.4rem 0 0 0;
    margin: 0 auto;
    width: 100%;
}

@media screen and (min-width: 800px) {
    .NavContainer ul {
        margin: 0 auto;
        width: 100%;
        padding: 0;
    }
}

.NavContainer ul li {
    padding: .7rem 2rem .7rem 1.4rem;
    list-style: none;
    font-weight: 600;
    font-size: 13px;
}

@media screen and (min-width: 1200px) {
    .NavContainer ul li {
        padding: .7rem 3rem .7rem 2rem;
        font-size: 16px;
    }
}

@media screen and (min-width: 1700px) {
    .NavContainer ul li {
        padding: .7rem 3rem .7rem 3rem;
    }
}

.NavContainer ul li:hover {
    background-color: #3f3e3e;
}

.Navlink {
    text-decoration: none;
    font-weight: 400;
    font-size: 1rem;
    display: flex;
}

.NavlinkMobile {
    justify-content: flex-start;
    text-decoration: none;
    padding: .5rem 0;
    text-align: left;
    font-weight: 400;
    margin: 0 auto;
    display: flex;
    width: 50%;
}

@media screen and (min-width: 500px) {
    .NavlinkMobile {
        justify-content: flex-start;
        padding: .5rem 0 .5rem 2rem;
        text-decoration: none;
        display: flex;
        width: 80%;
    }
}

.transition{
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.Navlink img {
    margin-right: 1rem;
}

.NavlinkHelp {
    padding: 1rem 0.5rem 1rem 0;
    justify-content: flex-start;
    text-decoration: none;
    font-size: 1.2rem;
    text-align: left;
    display: flex;
    margin: auto;
    width: 50%;
}

@media screen and (min-width: 500px) {
    .NavlinkHelp {
        padding-left: 2rem;
        width: 100%;
    }
}

.NavlinkHelp img {
    margin: auto 1.5rem auto 0;
}

.NavlinkHelp p {
    margin: 0 0 0 .5rem;
}

.DrawerButtonContainer {
    padding: .7rem 2rem .7rem 1.4rem;
    list-style: none;
    font-weight: 600;
    font-size: 13px;
}

@media screen and (min-width: 1200px) {
    .DrawerButtonContainer {
        padding: .7rem 3rem .7rem 2rem;
        font-size: 16px;
    }
}

@media screen and (min-width: 1700px) {
    .DrawerButtonContainer {
        padding: .7rem 3rem .7rem 3rem;
    }
}

.DrawerButtonContainer:hover {
    background-color: #3f3e3e;
}

.DrawerButtonContainerMobile {
    list-style: none;
    font-weight: 600;
    font-size: 16px;
}

.DrawerContainerMobile {
    padding: .5rem 1rem .5rem 2.5rem !important;
    align-items: center;
    font-size: 14px;
    display: flex;
}

.DrawerContainer {
    padding: .5rem 3rem .5rem 3.5rem !important;
    align-items: center;
    display: flex;
}

@media screen and (min-width: 1200px) {
    .DrawerContainer {
        padding: .5rem 3rem .5rem 4.5rem !important;
    }
}

@media screen and (min-width: 1700px) {
    .DrawerContainer {
        padding: .5rem 3rem .5rem 5.5rem !important;
    }
}

.NavlinkDrawerMobile {
    justify-content: center;
    text-decoration: none;
    align-items: center;
    margin-left: -1rem;
    font-size: .9rem;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    width: 100%;
}

@media screen and (min-width: 400px) {
    .NavlinkDrawerMobile {
        margin-left: -2.5rem;
    }
}

@media screen and (min-width: 500px) {
    .NavlinkDrawerMobile {
        margin-left: -.5rem;
    }
}

.NavlinkDrawerMobile1 {
    justify-content: center;
    text-decoration: none;
    align-items: center;
    margin-left: -1rem;
    font-size: .9rem;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    width: 100%;
}

@media screen and (min-width: 400px) {
    .NavlinkDrawerMobile1 {
        margin-left: -2.5rem;
    }
}

@media screen and (min-width: 500px) {
    .NavlinkDrawerMobile1 {
        margin-left: -.5rem;
    }
}

.NavlinkDrawer {
    text-decoration: none;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    width: 90%;
}

@media screen and (min-width: 1200px) {
    .NavlinkDrawer {
        font-size: .8rem;
    }
}

@media screen and (min-width: 1700px) {
    .NavlinkDrawer {
        font-size: 1rem;
    }
}

@media screen and (min-width: 800px) {
    .NavlinkHelp {
        justify-content: flex-start;
        padding-left: 0rem;
    }
}

.ReviewDrawerButtonContainer {
    justify-content: flex-start;
    text-decoration: none;
    padding: .5rem 0;
    text-align: left;
    font-weight: 400;
    margin: 0 auto;
    display: flex;
    width: 50%;
}

@media screen and (min-width: 500px) {
    .ReviewDrawerButtonContainer {
        justify-content: flex-start;
        padding: .5rem 0 .5rem 2rem;
        text-decoration: none;
        display: flex;
        width: 80%;
    }
}

@media screen and (min-width: 1200px) {
    .ReviewDrawerButtonContainer {
        padding: .7rem 0rem .7rem 2rem;
        font-size: 16px;
    }
}

.ReviewDrawerButtonContainer:hover {
    background-color: #3f3e3e;
}

.NavlinkReview {
    text-decoration: none;
    font-weight: 400;
    font-size: 1rem;
    display: flex;
}

.DividerLineMobile {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 1px;
    margin: 1rem auto;
    height: 1px; 
    width: 90%;
}

.DividerLine {
    background-color: rgba(255, 255, 255, 0.7);
    margin: 1rem auto;
    border-radius: 2px;
    height: 1.8px; 
    width: 90%;
}

.ComplementLinksMobile {
    text-decoration: none;
    padding-left: 2rem;
    text-align: left;
    font-weight: 400;
    color: white;
    margin: auto;
    width: 50%;
}

.ComplementLinks {
    text-decoration: none;
    text-align: left;
    font-weight: 400;
    color: white;
    width: 100%;
}

