.PaymentFormContainer {
    background-color: white;
    justify-content: center;
    align-content: center;
    overflow-y: auto;
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 1;
}