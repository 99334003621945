.QuestionComponent {
    justify-content: space-between;
    grid-template-rows: 25% 75%;
    background-color: white;
    box-sizing: border-box;
    align-items: center;
    border-radius: 8px;
    margin: .5rem 0;
    padding: 1rem;
    width: 100%;
}

.TopContainer {
    justify-content: space-between;
    background-color: white;
    align-items: center;
    display: flex;
    width: 100%;
}

.SwitchContainer {
    align-items: center;
    display: flex;
    width: 100%;
    gap: 10px;
}

.Switch {
    display: none;
}

@media screen and (min-width: 1000px) {
    .Switch {
        display: block;
    }
}

.ButtonsContainer {
    display: none;
}

@media screen and (min-width: 1000px) {
    .ButtonsContainer {
        display: flex;
    }
}

.MobileButtonsContainer {
    display: flex;
}

@media screen and (min-width: 1000px) {
    .MobileButtonsContainer {
        display: none;
    }
}

.Question {
    font-family: 'Poppins', sans-serif;
    border: 1px solid #BFBBBD;
    border-radius: 8px;
    font-size: .8rem;
    font-weight: 500;
    padding: .5rem;
    width: 100%;
}

@media screen and (min-width: 450px) {
    .Question {
        font-size: .9rem;
    }
}

@media screen and (min-width: 1000px) {
    .Question {
        font-size: 1rem;
        height: 25px;
    }
}

.Question:focus {
    border: 1px solid #2ED588;
}

.Question:focus-visible {
    outline: 0px;
}

.Question:disabled {
    background-color: white;
}

.QuestionDiv {
    font-size: .8rem;
    font-weight: 500;
}

@media screen and (min-width: 450px) {
    .QuestionDiv {
        font-size: .9rem;
    }
}

@media screen and (min-width: 1000px) {
    .QuestionDiv {
        font-size: 1rem;
    }
}

.BottomContainer {
    padding: .5rem 0rem 0rem 0rem;
    position: relative;
    margin-top: 1rem;
    grid-row: 2/3;
    width: 100%;
}

.BottomContainer p {
    background-color: white;
    padding: 0 .9rem 0 .4rem;
    position: absolute;
    font-size: .9rem;
    color: #BFBBBD;
    top: -4px;
    margin: 0;
    left: 1%;
}

.TextArea {
    font-family: 'Poppins', sans-serif;
    border: 1px solid #BFBBBD;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    font-size: .8rem;
    padding: .7rem;
    resize: none;
    width: 100%;
    height: 65%;
}

.TextArea:focus-visible {
    border: 1px solid #2ED588 !important;
    outline: none !important;
}

@media screen and (min-width: 450px) {
    .TextArea {
        font-size: .9rem;
    }
}

@media screen and (min-width: 1000px) {
    .TextArea {
        font-size: 1rem;
    }
}

.SaveButtonContainer {
    justify-content: flex-end;
    margin: .5rem 0;
}

.DotsContainer {
    align-items: center;
    padding: .5rem;
    display: flex;
    cursor: move;
}

.DraggContainer {
    align-items: center; 
    display: flex; 
}

