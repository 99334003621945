.HomeScreenContainer {
    padding: 2rem 1rem 1rem 1rem;
}

@media screen and (min-width: 1350px) {
    .HomeScreenContainer {
        grid-template-columns: 60% 40%;
        display: grid;
    }
}

.Title {
    font-weight: 600;
    text-align: left;
    margin: 0;
}

.LeftSection {
    grid-column: 1/2;
}

.BottomSection {
    margin: 1rem auto 0 auto;
    display: block;
    width: 99%;
}

@media screen and (min-width: 1000px) {
    .BottomSection {
        grid-template-columns: repeat(2, 1fr);
        margin: 2.5rem auto 0 auto;
        display: grid;
        gap: 30px;
    }
}

.RightSection {
    box-sizing: border-box;
    display: block;
    margin: auto;
    width: 100%;
}

@media screen and (min-width: 1000px) {
    .RightSection {
        display: flex;
        gap: 30px;
    }
}

@media screen and (min-width: 1350px) {
    .RightSection {
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        grid-column: 2/3;
        padding: 0 1rem;
        gap: 0px;
    }
}