.RankingGeneralContainer {
    box-shadow: 0px 7.11616px 183.241px rgba(50, 50, 71, 0.01), 0px 7.11616px 104.963px rgba(50, 50, 71, 0.06);
    background-color: white;
    border-radius: 10px;
    height: max-content;
    overflow-y: auto;
    width: 100%;
}

@media screen and (min-width: 1000px) {
    .RankingGeneralContainer {
        min-height: 350px;
    }
}


@media screen and (min-width: 1500px) {
    .RankingGeneralContainer {
        min-height: 390px;
    }
}

/* .RankingGeneralContainer::-webkit-scrollbar {
    -webkit-appearance: none;
    right: 10px;
}

.RankingGeneralContainer::-webkit-scrollbar:vertical {
    width: 20px;
}

.RankingGeneralContainer::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
    display: none;
} 

.CardsSection::-webkit-scrollbar:horizontal {
    height: 7px;
}

.RankingGeneralContainer::-webkit-scrollbar-thumb {
    background-color: #2ED588;
    border: 7px solid white;
    border-radius: 20px;
}

.RankingGeneralContainer::-webkit-scrollbar-track {
    border-radius: 10px;  
} */

.TopContainer {
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    background-color: white;
    box-sizing: border-box;
    align-items: center;
    position: sticky;
    padding: 0 1rem;
    margin: 0 auto;
    display: flex;
    width: 100%;
    top: 0;
}

.TopContainer h1 {
    font-weight: 500;
    font-size: 18px;
}

.TopContainer p {
    background-color: #E3FFF2;
    padding: .3rem .5rem;
    border-radius: 8px;
    color: #2ED588;
    font-size: .9rem;
    font-weight: 500;
    display: none;
}

@media screen and (min-width: 450px) {
    .TopContainer p {
        display: block;
    }
}

.RankingContainer {
    padding-bottom: .5rem;
    padding-right: .5rem;
    padding-left: .5rem;
}

@media screen and (min-width: 450px) {
    .RankingContainer {
        padding-left: .5rem;
        padding-right: 0;
    }
}

.ImgBackRanking {
    width: 60% !important;
}

@media screen and (min-width: 400px) {
    .ImgBackRanking {
        width: 50% !important;
    }
}

@media screen and (min-width: 400px) {
    .ImgBackRanking {
        width: 30% !important;
    }
}

@media screen and (min-width: 1300px) {
    .ImgBackRanking {
        width: 35% !important;
    }
}

.BackContainerText {
    margin: .5rem auto .8rem auto;
    color: #C4C4C4;
    font-size: .9rem;
    width: 90%;
}

@media screen and (min-width: 400px) {
    .BackContainerText {
        margin: .5rem auto 1rem auto;
        font-size: 1rem;
        width: 80%;
    }
}