.Title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

@media screen and (min-width: 800px) {
    .Title {
        font-size: 1.8rem;
    }
}

.DataSection {
    padding: 1rem 0rem;
    max-width: 680px;
    margin: auto;
    width: 100%;
}

.DataSection h2 {
    font-weight: 500;
    font-size: 1rem;
}

.TextAreaContainer {
    width: 100%;
}

.InputMessage {
    font-family: 'Poppins', sans-serif;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 1rem;
    padding: 1rem;
    resize: none;
    width: 100%;
}

.InputMessage:focus-visible {
    border: 1px solid #2ED588 !important;
    outline: none !important;
}

.ChipSection {
    scroll-behavior: smooth;
    padding: 0 0 .4rem 0;
    margin-bottom: .5rem;
    align-items: center;
    position: relative;
    overflow-x: auto;
    display: flex;
    gap: 10px;
}

.ChipSection::-webkit-scrollbar {
    background-color: #F2F6FA;
    -webkit-appearance: none;
    display: none;
    height: 5px;
}

.ChipSection::-webkit-scrollbar:vertical {
    width: 3px;
}

.ChipSection::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
    display: none;
} 

.ChipSection::-webkit-scrollbar-thumb {
    background-color: #BFBBBD;
    border: 1px solid #BFBBBD;
    border-radius: 20px;
}

.ChipSection::-webkit-scrollbar-track {
    border-radius: 10px;  
}

.InputMessageType {
    font-family: 'Poppins', sans-serif;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 1rem;
    padding: 1rem;
    resize: none;
    width: 100%;
}

.InputMessageType:focus-visible {
    outline: none !important;
    border: 1px solid #2ED588 !important;
}

.MaxCharacters {
    text-align: right;
    font-size: .7rem;
    color: #BFBBBD;
    width: 100%;
    margin: 0;
}

.MaxCharactersWelcome {
    text-align: right;
    font-size: .7rem;
    color: #BFBBBD;
    width: 100%;
    margin: 0;
}

.TextAreaContainer {
    box-sizing: border-box;
    padding: 0 1rem;
}

.TextContainer h2 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 0;
}

.TextContainer h3 {
    font-size: .85rem;
    font-weight: 500;
    margin-top: 0;
}

.TextContainer p {
    font-size: .75rem;
}

.ChipsContainer { 
    box-sizing: border-box; 
    position: relative; 
    padding: 0 1rem;
    width: 100%;
}

.LinearGradientContainer {
    background: linear-gradient(90deg, #F2F6FA 0%, transparent 5%, transparent 90%, #F2F6FA 100%);
    width: -webkit-fill-available;
    pointer-events: none;
    position: absolute;
    height: 33px;
    z-index: 1;
}

@media screen and (min-width: 600px) {
    .LinearGradientContainer {
        display: none;
    }
}

.ArrowLeftContainer {
    background: linear-gradient(90deg, #F9F8FF 0%, #F9F8FF 25%, #F9F8FF 75%, transparent 100%);
    padding-right: .2rem;
    align-items: center;
    position: absolute;
    cursor: pointer;
    left: -1.4rem;
    height: 38px;
    z-index: 1;
    top: 0;
}

.ArrowContainer {
    background: linear-gradient(90deg, transparent 0%, #F9F8FF 25%, #F9F8FF 88%, #F9F8FF 100%);
    padding-left: .2rem;
    align-items: center;
    position: absolute;
    cursor: pointer;
    right: -1.4rem;
    height: 38px;
    z-index: 1;
    top: 0;
}