.DevicesGeneralContainer {
    box-shadow: 0px 7.11616px 183.241px rgba(50, 50, 71, 0.01), 0px 7.11616px 104.963px rgba(50, 50, 71, 0.06);
    padding: 1rem 1rem 1rem 2rem;
    background-color: white;
    box-sizing: border-box;
    border-radius: 15px;
    height: auto;
    width: 100%;
}

@media screen and (min-width: 600px) {
    .DevicesGeneralContainer {
        padding: 1rem;
        width: 100%;
        margin: 0;
    }
}

@media screen and (min-width: 1500px) {
    .DevicesGeneralContainer {
    }
}

.DevicesGeneralContainer h1 {
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
}

@media screen and (min-width: 500px) {
    .DevicesGeneralContainer h1 {
        font-size: 22px;
    }
}

.DataSection {
    display: block;
    height: 100%;
    min-height: 140px;
}

@media screen and (min-width: 400px) {
    .DataSection {
        grid-template-columns: 55% 45%;
        display: grid;
    }
}

@media screen and (min-width: 1500px) {
    .DataSection {
        grid-template-columns: repeat(2, 1fr);
    }
}

.LeftSection {
    justify-content: center;
    flex-direction: column;
    grid-column: 1/2;
    padding-left: 0;
    display: flex;
}

@media screen and (min-width: 1500px) {
    .LeftSection {
        padding-left: 1rem;
    }
}

.RightSection {
    grid-column: 2/3;
    height: 130px;
    margin: auto;
    width: 100%;
}

@media screen and (min-width: 450px) {
    .RightSection {
        height: 85%;
    }
}

.ItemSection {
    justify-content: center;
    margin: 1rem 0 .5rem 0;
    align-items: center;
    display: flex;
}

@media screen and (min-width: 400px) {
    .ItemSection {
        justify-content: flex-start;
    }
}

@media screen and (min-width: 800px) {
    .ItemSection {
        margin: 0;
    }
}

.ItemSection h1 {
    margin: 0 0 0 0.5rem;
    font-size: 1.3rem;
}

.IconSection {
    align-items: center;
    display: flex;
    gap: 5px;
}

.IconSection p {
    display: none;
}

@media screen and (min-width: 800px) {
    .IconSection p {
        display: block;
    }
}

@media screen and (min-width: 1500px) {
    .IconSection p {
        font-size: 1.2rem;
    }
}