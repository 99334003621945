.ModalContainer {
    transform: translate(-50%, -50%);
    border: 1px solid #BFBBBD;
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
    position: absolute;
    text-align: center;
    padding-top: 1rem;
    overflow-y: auto;
    height: 95%;
    width: 95%;
    left: 50%;
    top: 50%;
}

@media screen and (min-height: 650px) {
    .ModalContainer {
        height: auto;
    }
}

@media screen and (min-width: 500px) {
    .ModalContainer {
        height: auto;
        width: 450px;
    }
}

@media screen and (min-width: 900px) {
    .ModalContainer {
        width: 450px;
    }
}

.ModalContainer:focus-visible {
    outline: none;
}

.WhatsPROIcon {
    height: 44px;
}

.EcommerceIcon {
    margin-left: 1rem;
    height: 44px;
}

@media screen and (min-width: 500px) {
    .EcommerceIcon, .WhatsPROIcon {
        height: 54px;
    }
}

