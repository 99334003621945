.Step2Container {
    padding-bottom: 2rem;
}

@media screen and (min-width: 1700px) {
    .Step2Container {
        padding-bottom: 4rem;
    }
}

.Title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

@media screen and (min-width: 800px) {
    .Title {
        font-size: 1.8rem;
    }
}

.ContainerPersonalizeHours {
    background-color: white;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    height: 370px;
    display: flex;
    width: 100%;
}

@media screen and (min-width: 950px) {
    .ContainerPersonalizeHours {
        height: 320px;
    }
}

.TitleSection {
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: .5rem;
    align-items: center;
    padding: 2rem 1rem;
    display: flex;
    height: 30px;
    width: 100%;
}

.TitleSection h2 {
    font-size: .9rem;
    font-weight: 500;
    display: flex;
    margin: 0;
    gap: 5px;
}

@media screen and (min-width: 330px) {
    .TitleSection h2 {
        font-size: 1.1rem;
    }
}

.PersonalizeWord {
    display: none;
}

@media screen and (min-width: 550px) {
    .PersonalizeWord {
        display: flex;
    }
}

.HoursContainer {
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-between;
    padding: 1rem 1rem 4rem 1rem;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
    display: grid;
    gap: 20px;
}

@media screen and (min-width: 750px) {
    .HoursContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 1000px) {
    .HoursContainer {
        display: block;
    }
}

.HoursContainer::-webkit-scrollbar {
    -webkit-appearance: none;
    right: 10px;
}

.HoursContainer::-webkit-scrollbar:vertical {
    width: 20px;
}

.HoursContainer::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
    display: none;
} 

.CardsSection::-webkit-scrollbar:horizontal {
    height: 7px;
}

.HoursContainer::-webkit-scrollbar-thumb {
    background-color: #F0EFEF;
    border: 7px solid white;
    border-radius: 20px;
}

.HoursContainer::-webkit-scrollbar-track {
    border-radius: 10px;  
}

.ButtonContainer {
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 80%, transparent 100%);
    background: rgb(255,255,255);
    box-sizing: border-box;
    border-radius: 10px;
    position: absolute;
    padding: 1rem;
    width: 93%;
    z-index: 1;
    bottom: 0;
}

@media screen and (min-width: 450px) {
    .ButtonContainer {
        width: 100%;
    }
}

@media screen and (min-width: 1700px) {
    .ButtonContainer {
        width: 97%;
    }
}

/* Texts */
.TextContainer {
    padding: 2.5rem 0 0 0;
}

.TextContainer h2 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
}

.TextContainer h3 {
    font-size: .85rem;
    font-weight: 500;
    margin-top: 0;
}

.TextContainer p {
    font-size: .75rem;
}

.TextContainer span {
    font-weight: 600;
    font-size: .85rem;
}