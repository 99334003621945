.IntegrationsScreenContainer {
    padding: 2rem 1.5rem 9rem 1.5rem;
    user-select: none;
    text-align: left;
}

@media screen and (min-width: 500px) {
    .IntegrationsScreenContainer {
        padding: 2rem 0 0 2.5rem;
    }
}

@media screen and (min-width: 1550px) {
    .IntegrationsScreenContainer {
        padding: 2rem 0 0 5rem;
    }
}

.Title {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
}

@media screen and (min-width: 600px) {
    .Title {
        font-size: 1.7rem;
    }
}

.Subtitle {
    font-size: .9rem;
    color: #393939;
    margin: 0;
}

@media screen and (min-width: 600px) {
    .Subtitle {
        font-size: 1rem;
    }
}

.OptionsContainer {
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 10px;
    overflow-x: auto;
    margin: 2rem 0;
    display: grid;
    padding: 1rem;
    width: 100%;
    gap: 10px;
}

@media screen and (min-width: 1550px) {
    .OptionsContainer {
        overflow-x: hidden;
        width: 85%;
    }
}

.OptionsContainer::-webkit-scrollbar {
    height: 10px;
}

.OptionsContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
    margin: 3px;
}

.OptionsContainer::-webkit-scrollbar-thumb {
    background: #2ED588;
    border-radius: 5px;
}

.OptionsContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.StepsContainer {
    border: 1px solid #2ED588;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
}

@media screen and (min-width: 600px) {
    .StepsContainer {
        padding: 1.5rem;
    }
}

@media screen and (min-width: 1550px) {
    .StepsContainer {
        width: 85%;
    }
}

.StepsContainer h1 {
    font-weight: 500;
    font-size: 1rem;
}

@media screen and (min-width: 1550px) {
    .StepsContainer h1 {
        font-size: 1.2rem;
    }
}