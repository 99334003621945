.HoursComponentsContainer {
    display: none;
}

@media screen and (min-width: 1000px) {
    .HoursComponentsContainer {
        justify-content: space-between;
        box-sizing: border-box;
        border-radius: 10px;
        padding: .9rem 1rem;
        align-items: center;
        margin: 0 0 1rem 0;
        display: flex;
        width: 100%;
    }
}

.TitleSection {
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    display: flex;
    grid-row: 1/2;
}

@media screen and (min-width: 1000px) {
    .TitleSection {
        flex-direction: row;
    }
}

.TitleSection h2 {
    font-size: .9rem;
    font-weight: 500;
}

@media screen and (min-width: 1000px) {
    .TitleSection h2 {
        font-size: 1rem;
    }
}

.Division {
    display: none;
}

@media screen and (min-width: 1000px) {
    .Division {
        background-color: #BFBBBD;
        display: block;
        height: 39px;
        width: 2px;
    }
}

.LastDivision {
    display: none;
}

@media screen and (min-width: 1000px) {
    .LastDivision {
        background-color: #BFBBBD;
        margin: 0rem .5rem;
        display: block;
        height: 39px;
        width: 2px;
    }
}

.Rangehours {
    display: none;
}

.RangehoursActive {
    align-items: center;
    margin-top: -1rem;
    display: block;
    grid-row: 2/3;
    width: auto;
}

@media screen and (min-width: 450px) {
    .Rangehours {
        display: none;
        width: auto;
        gap: 10px;
    } 
    
    .RangehoursActive {
        margin-top: -.5rem;
        display: flex;
        width: auto;
        gap: 10px;
    }
}

@media screen and (min-width: 1000px) {
    .Rangehours {
        align-items: center;
        display: flex;
        grid-row: 1/2;
        gap: 25px;
    }

    .RangehoursActive {
        margin: 0;
        gap: 25px;
    }
}

.RangehourItem {
    align-items: center;
    display: flex;
    width: 90%;
}

@media screen and (min-width: 1000px) {
    .RangehourItem {
        width: auto;
    }
}

.RangehourItem h3, .RangehourItem h3 {
    font-size: .9rem;
    color: #BFBBBD;
    font-weight: 500;
    margin: 0;
}

.DaysContainer {
    align-items: center;
    padding: 0 .5rem;
    grid-row: 1/2;
    display: flex;
}

.DaysContainerActive {
    align-items: center;
    grid-column: 1/2;
    padding: 0 .5rem;
    display: flex;
    grid-row: 3/4;
    gap: 0;
}

@media screen and (min-width: 1200px) {
    .DaysContainer {
        align-items: center;
        padding: 0 .5rem;
        grid-row: 1/2;
    }

    .DaysContainerActive {
        grid-row: 1/2;
    }
}

.BottomContainer {
    justify-content: space-between;
    margin-top: -5px;
    display: block;
    padding: 0;
}

@media screen and (min-width: 450px) {
    .BottomContainer {
        display: flex;
        margin-top: 0;
    }
}

@media screen and (min-width: 1000px) {
    .BottomContainer {
        margin-left: -25px;
    }
}

.DayContainer {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    padding: .5rem;
    display: flex;
    height: 4px;
    width: 4px;
}

@media screen and (min-width: 1000px) {
    .DayContainer {
        padding: .5rem;
        height: 9px;
        width: 9px;
    }
}

@media screen and (min-width: 1100px) {
    .DayContainer {
        padding: .6rem;
        height: 10px;
        width: 10px;
    }
}

.DayContainer h2 {
    font-weight: 600;
    font-size: .7rem;
    color: white;
}

@media screen and (min-width: 500px) {
    .DayContainer h2 {
        font-size: .9rem;
    }
}

.ButtonEditContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 80px;
}

