.HoursComponentsContainerActive {
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 10px;
    height: max-content;
    padding: .5rem 1rem;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    width: auto;
    gap: 15px;
    margin: 0 0 1rem 0;
}

@media screen and (min-width: 400px) {
    .HoursComponentsContainerActive {
        max-width: 320px;
        width: 98%;
    }
}

@media screen and (min-width: 700px) {
    .HoursComponentsContainerActive {
        overflow-x: hidden;
        max-width: 100%;
    }
}

.HoursComponentsContainer {
    grid-template-rows: repeat(2, 1fr);
    padding: .5rem 0rem .5rem 1rem;
    margin: 0rem 0rem 1rem 0rem;
    border-radius: 10px;
    overflow-y: hidden;
    overflow-x: auto;
    display: grid;
    height: 28px;
    gap: 5px;
}

@media screen and (min-width: 1000px) {
    .HoursComponentsContainer, .HoursComponentsContainerActive {
        display: none;
    }
}

.TitleSection {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    display: flex;
    grid-row: 1/2;
}

.TitleSection h2 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
}

.OptionsContainer {
    flex-direction: column;
    display: flex;
    gap: 15px;
}

@media screen and (min-width: 450px) {
    .OptionsContainer {
        gap: 25px;
    }
}

@media screen and (min-width: 700px) {
    .OptionsContainer {
        flex-direction: row;
    }
}

@media screen and (min-width: 750px) {
    .OptionsContainer {
        flex-direction: column;
    }
}

.Rangehours {
    display: none;
}

.RangehoursActive {
    flex-direction: column;
    margin-bottom: 5px;
    margin-top: 0rem;
    display: flex;
    grid-row: 1/2;
    width: auto;
    gap: 15px;
}

@media screen and (min-width: 400px) {
    .RangehoursActive {
        flex-direction: row;
        gap: 40px;
    }
}

@media screen and (min-width: 450px) {
    .Rangehours {
        margin-top: auto;
        display: none;
        width: 300px;
    } 
}

@media screen and (min-width: 700px) {
    .RangehoursActive {
        width: 330px;
    }
}

@media screen and (min-width: 750px) {
    .RangehoursActive {
        gap: 50px;
    }
}

@media screen and (min-width: 1700px) {
    .Rangehours {
        align-items: center;
        display: flex;
        grid-row: 1/2;
    }

    .RangehoursActive {
        margin-top: auto;
    }
}

.RangehourItem {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

@media screen and (min-width: 1700px) {
    .RangehourItem {
        width: auto;
    }
}

.RangehourItem h3, .RangehourItem h3 {
    font-size: 1.1rem;
    color: #BFBBBD;
    font-weight: 500;
    margin: 0;
}

.DaysContainer {
    display: none;
}

.DaysContainerActive {
    align-items: center;
    grid-column: 1/2;
    display: flex;
    grid-row: 3/4;
    padding: 0;
    gap: 0;
}

.BottomContainer {
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px;
    display: flex;
    grid-row: 2/3;
    padding: 0;
}

@media screen and (min-width: 450px) {
    .BottomContainer {
        margin-top: -13px;
    }
}

.DayContainer {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    padding: .6rem;
    display: flex;
    height: 4px;
    width: 4px;
}

@media screen and (min-width: 400px) {
    .DayContainer {
        padding: .6rem;
        height: 8px;
        width: 8px;
    }
}

@media screen and (min-width: 600px) {
    .DayContainer {
        padding: .6rem;
        height: 10px;
        width: 10px;
    }
}

.DayContainer h2 {
    font-weight: 600;
    font-size: .7rem;
    color: white;
}

@media screen and (min-width: 400px) {
    .DayContainer h2 {
        font-size: .9rem;
    }
}

