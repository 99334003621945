.AnswerContainer {
    border: 1px solid #BFBBBD;
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    max-width: 680px;
    padding: 1rem;
    margin: auto;
    width: 100%;
}

.ChipContainer {
    padding: .5rem 0 .8rem 0;
    overflow-x: auto;
    display: flex;
    gap: 20px;
}

@media screen and (min-width: 600px) {
    .ChipContainer {
        padding: .5rem 2rem .5rem 2rem;
    }
}

.ChipContainer::-webkit-scrollbar {
    display: none;
}

.LinearGradientContainer {
    background: linear-gradient(90deg, white 0%, transparent 15%, transparent 88%, white 100%);
    pointer-events: none;
    position: absolute;
    margin-left: -1rem;
    height: 45px;
    width: 100%;
    z-index: 1;
}

@media screen and (min-width: 600px) {
    .LinearGradientContainer {
        display: none;
    }
}

.ArrowLeftContainer {
    background: linear-gradient(90deg, white 0%, white 25%, white 75%, transparent 100%);
    padding-right: .2rem;
    align-items: center;
    position: absolute;
    cursor: pointer;
    height: 50px;
    left: .5rem;
    z-index: 1;
    top: 1rem;
}

.ArrowContainer {
    background: linear-gradient(90deg, transparent 0%, white 8%, white 75%, white 100%);
    padding-left: .2rem;
    align-items: center;
    position: absolute;
    cursor: pointer;
    right: .2rem;
    height: 50px;
    z-index: 1;
    top: 1rem;
}

.QuestionsGeneralComponents {
    padding: 0rem 1rem 1rem 0;
    position: relative;
}

@media screen and (min-width: 1000px) {
    .QuestionsGeneralComponents {
        padding: 1rem 1rem 1rem 0;
        overflow-x: hidden;
    }
}