.DelayButttonContainer {
    grid-template-columns: 80% 20%;
    border: 1px solid #2ED588;
    box-sizing: border-box;
    border-radius: 10px;
    grid-row: 1/2;
    display: grid;
    height: 4rem;
    width: 100%;
}

@media screen and (min-width: 500px) {
    .DelayButttonContainer {
        width: 90%;
    }
}

@media screen and (min-width: 900px) {
    .DelayButttonContainer {
        width: 100%;
    }
}

@media screen and (min-width: 550px) {
    .DelayButttonContainer {
        grid-template-columns: 90% 10%;
    }
}

@media screen and (min-width: 900px) {
    .DelayButttonContainer {
        max-width: 500px;
        width: 100%;
    }
}

.SecondsSection {
    border-radius: 10px 0px 0px 10px;
    justify-content: flex-start;
    background-color: white;
    align-items: center;
    padding-left: 2rem;
    user-select: none;
    grid-column: 1/2;
    display: flex;
}

.SecondsSection p {
    color: #BFBBBD;
}

.ButtonsSection {
    grid-template-rows: repeat(2, 1fr);
    grid-column: 2/3;
    display: grid;
}

.ButtonTop, .ButtonBottom {
    background-color: #E3FFF2;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
}

.ButtonTop {
    border-bottom: .2px solid #2ED588;
    border-left: .2px solid #2ED588;
    border-radius: 0px 10px 0px 0px;
    grid-row: 1/2;
}

.ButtonTop:hover {
    background-color: #CCFFE7;
}

.ButtonBottom {
    border-left: .2px solid #2ED588;
    border-radius: 0px 0px 10px 0px;
    grid-row: 2/3;
}

.ButtonBottom:hover {
    background-color: #CCFFE7;
}