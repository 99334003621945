.SwitchButtonContainer {
    justify-content: space-between;
    background-color: white;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 1rem;
    height: max-content;
    grid-column: 1/2;
    display: flex;
    padding: 1rem;
    width: 100%;
}

@media screen and (min-width: 500px) {
    .SwitchButtonContainer {
        height: 4.5rem;
        width: 90%;
    }
}

@media screen and (min-width: 900px) {
    .SwitchButtonContainer {
        width: 500px;
    }
}

@media screen and (min-width: 1550px) {
    .SwitchButtonContainer {
        width: 100% ;
    }
}

.TextsSection h2 {
    font-weight: 500;
    font-size: 1rem;
    margin: 0;
}

.TextsSection p {
    font-size: .7rem;
    margin: 0;
}