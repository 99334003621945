.PerformanceContainer {
  box-shadow: 0px 4px 103px rgba(50, 50, 71, 0.01),
    0px 4px 59px rgba(50, 50, 71, 0.06);
  background-color: white;
  border-radius: 15px;
  padding: 1rem;
  box-sizing: border-box;
  height: 350px;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .PerformanceContainer {
    height: 400px;
    width: 100%;
  }
}

@media screen and (min-width: 1500px) {
  .PerformanceContainer {
    height: 450px;
  }
}

.TopSection {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.TopSection h1 {
  margin-bottom: 1rem;
  margin-left: 1rem;
  font-weight: 500;
  text-align: left;
  font-size: 18px;
  margin-top: 0;
}

@media screen and (min-width: 500px) {
  .TopSection h1 {
    font-size: 22px;
  }
}

.BottomSection {
  margin-left: 0rem;
  overflow-y: hidden;
  margin-left: 0rem;
  overflow-x: auto;
  height: 85%;
}

@media screen and (min-width: 500px) {
  .BottomSection {
    margin-left: -1.5rem;
  }
}

@media screen and (min-width: 900px) {
  .BottomSection {
    height: 85%;
  }
}
